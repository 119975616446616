import httpClient from '@/utilities/http/http-client'
import _ from 'lodash'

const endpoint = 'api/v1/ipl'

export default {
  getTermins (params) {
    const paramsFiltered = _.omitBy(params, _.isNull)
    const queryParams = paramsFiltered ? `?${new URLSearchParams(paramsFiltered).toString()}` : ''
    return httpClient.get(`${endpoint}/termins${queryParams}`)
  },

  showTermin (idIpl) {
    return httpClient.get(`${endpoint}/termins/${idIpl}`)
  },

  createTermin (params) {
    return httpClient.post(`${endpoint}/termins`, params)
  },

  updateTermin (params) {
    return httpClient.patch(`${endpoint}/termins`, params)
  },

  deleteTermin (idIpl) {
    return httpClient.delete(`${endpoint}/termins/${idIpl}`)
  }
}
