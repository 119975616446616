<template>
  <vs-popup class="sm:popup-w-80" title="Pengakuan Pendapatan IPL" :active="isActive" v-on:update:active="emitModalIsActive">
    <div :class="{'pointer-events-none opacity-50': loading.initData}">
      <div class="flex flex-col" style="height: calc(100vh - 370px);">
        <!--CONTENT-->
        <div class="flex flex-col flex-1">
          <div class="flex flex-none">
            <ValidationErrors :errors="errors"/>
          </div>

          <div class="vx-row flex flex-none flex-wrap">
            <!--form-->
            <div class="vx-col w-full lg:w-4/12">
              <div class="flex flex-col flex-none">
                <vs-divider class="mt-0 mb-0">Summary</vs-divider>
                <div class="flex items-center mt-6">
                  <label class="text-xs w-32">No IPL</label>
                  <vs-input class="w-full" :value="data.ipl.no_ipl" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Proyek</label>
                  <vs-input class="w-full" :value="data.ipl.kode_proyek_unit" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">No Unit</label>
                  <vs-input class="w-full" :value="data.ipl.no_unit" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Total Nilai</label>
                  <v-money class="w-full" :value="data.ipl.total_nilai" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Total Terbayar</label>
                  <v-money class="w-full" :value="data.ipl.total_bayar" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Total Sisa</label>
                  <v-money class="w-full" :value="data.ipl.total_sisa" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Tgl. Diakui</label>
                  <vs-input class="w-full" :value="data.ipl.tgl_ipl" readonly/>
                </div>
                <div class="flex items-center mt-1">
                  <label class="text-xs w-32">Keterangan</label>
                  <vs-input class="w-full" v-model="data.keterangan"/>
                </div>
              </div>
            </div>

            <!--journals-->
            <div class="vx-col w-full lg:w-8/12">
              <div class="flex flex-col flex-grow">
                <vs-divider class="mt-0 mb-0">
                  <div class="flex space-x-2">
                    <span>Journals</span>
                    <QueryEditor v-if="$can('view_query')" code="PENGAKUAN_PENDAPATAN_IPL_JURNAL"/>
                  </div>
                </vs-divider>
                <div>
                  <vs-button class="px-2 py-1 mb-1 text-xs text-center rounded-sm" color="primary" @click="addAdditionalJournal">+</vs-button>
                </div>
                <div class="overflow-auto d-theme-dark-bg border border-solid d-theme-border-grey-light" style="height: 23rem;">
                  <table class="table-fixed table-sticky border-collapse w-full">
                    <thead class="font-extrabold border border-solid d-theme-border-grey-light">
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">COA</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Nama</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Keterangan</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Debet</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">Kredit</th>
                    </tr>
                    </thead>
                    <tbody class="font-medium">
                    <tr v-for="(item, index) in data.journals" :key="index" class="cursor-pointer hover:font-medium">
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-left">
                        <div v-if="item.source === 'ADDITIONAL'" class="flex select-none">
                          <span class="text-danger mr-3" @click="removeJournal(item)">x</span>
                          <span class="underline" @click="showModalCoa(item)">{{ item.kode_coa ? item.kode_coa : 'Pilih COA' }}</span>
                        </div>
                        <span v-else class="underline" @click="showModalCoa(item)">{{ item.kode_coa }}</span>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-left">
                        {{ item.nama_coa }}
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-left">
                        <div v-if="item.source === 'ADDITIONAL'">
                          <input type="text" class="border-none w-full text-sm" v-model="item.keterangan"/>
                        </div>
                        <span v-else>{{ item.keterangan }}</span>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-right">
                        <div>
                          <v-money class="w-full" theme="theme2" v-model="item.debet"/>
                        </div>
                      </td>
                      <td class="text-xs px-2 py-1 h-6 border border-solid d-theme-border-grey-light text-right">
                        <div>
                          <v-money class="w-full" theme="theme2" v-model="item.kredit"/>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                    <tfoot>
                    <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap"></th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap" :class="journalsSummary.grandTotalDebet === journalsSummary.grandTotalKredit ? 'text-success' : 'text-danger'">{{ journalsSummary.grandTotalDebet | idr }}</th>
                      <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right whitespace-no-wrap" :class="journalsSummary.grandTotalDebet === journalsSummary.grandTotalKredit ? 'text-success' : 'text-danger'">{{ journalsSummary.grandTotalKredit | idr }}</th>
                    </tr>
                    </tfoot>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--FOOTER-->
        <div class="flex flex-none justify-end">
          <div class="flex mt-2">
            <vs-button class="mr-3" size="small" type="border" @click="emitModalIsActive(false)">Batal</vs-button>
            <vs-button size="small" :disabled="loading.posting" @click="confirmPosting">
              <span v-if="loading.posting" class="animate-pulse">Menyimpan...</span>
              <span v-if="!loading.posting">Simpan</span>
            </vs-button>
          </div>
        </div>
      </div>
    </div>

    <!--modals-->
    <vs-popup class="sm:popup-w-70 popup-content-no-padding"
              title="Pilih COA"
              :active.sync="modalCoa.active">
      <Coa :selectable="true" :externalFilter="filterCoa" @selected="onSelectedModalCoa"/>
    </vs-popup>

  </vs-popup>
</template>

<script>
import PengakuanPendapatanRepository from '@/repositories/accounting/pengakuan-pendapatan-repository'
import IplRepository from '@/repositories/estate/ipl-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import VMoney from '@/views/components/v-money/VMoney'
import _ from 'lodash'
import { v4 as uuid } from 'uuid'
import { convertToFormData } from '@/utilities/common/global-methods'

export default {
  name: 'PengakuanPendapatanIplPosting',
  props: {
    isActive: { required: true, type: Boolean },
    idIpl: { required: true, default: null }
  },
  components: {
    ValidationErrors,
    VMoney,
    Coa: () => import('@/views/pages/master/coa/Coa'),
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  watch: {
    isActive (active) {
      if (active) {
        this.initData()
      } else {
        this.resetData()
      }
    }
  },
  computed: {
    journalsSummary () {
      return {
        grandTotalDebet: _.sumBy(this.data.journals, item => parseFloat(item.debet || '0')),
        grandTotalKredit: _.sumBy(this.data.journals, item => parseFloat(item.kredit || '0'))
      }
    },
    filterCoa () {
      return {
        tipe: 'DETAIL'
      }
    }
  },
  data () {
    return {
      errors: null,
      loading: {
        posting: false,
        initData: false
      },
      modalCoa: {
        active: false,
        uuid: false
      },
      data: {
        tgl_diakui: null,
        keterangan: 'Pengakuan Pendapatan IPL',
        journals: [],
        ipl: {}
      }
    }
  },
  methods: {
    async initData () {
      this.loading.initData = true
      this.data.ipl = await this.getDataIpl()
      this.data.journals = await this.getDefaultJournals()
      this.data.keterangan = `Pengakuan Pendapatan IPL Nomor ${this.data.ipl.no_ipl}`
      this.loading.initData = false
    },

    async getDataIpl () {
      return new Promise((resolve, reject) => {
        IplRepository.showTermin(this.idIpl)
          .then(response => {
            resolve(response.data.data)
          })
          .catch(error => {
            reject(error)
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          })
      })
    },

    async getDefaultJournals () {
      return new Promise((resolve, reject) => {
        const params = { id_ipl: this.idIpl }
        PengakuanPendapatanRepository.getJurnalIpl(params)
          .then(response => {
            const jurnals = _.map(response.data.data, item => {
              return {
                source: 'DEFAULT',
                uuid: uuid(),
                id_coa: item.id_coa,
                kode_coa: item.kode_coa,
                nama_coa: item.nama_coa,
                keterangan: item.keterangan,
                debet: item.debet,
                kredit: item.kredit
              }
            })
            resolve(jurnals)
          })
          .catch(error => {
            reject(error)
            console.log(error)
            this.notifyError('Terjadi kesalahan.')
          })
      })
    },

    addAdditionalJournal () {
      this.data.journals.push({
        source: 'ADDITIONAL',
        uuid: uuid(),
        id_coa: null,
        kode_coa: null,
        nama_coa: null,
        keterangan: null,
        debet: 0,
        kredit: 0
      })
    },

    removeJournal (row) {
      const index = _.findIndex(this.data.journals, item => item.uuid === row.uuid)
      this.data.journals.splice(index, 1)
    },

    showModalCoa (item) {
      this.modalCoa.active = true
      this.modalCoa.uuid = item.uuid
    },

    onSelectedModalCoa (coa) {
      const index = _.findIndex(this.data.journals, item => item.uuid === this.modalCoa.uuid)
      this.data.journals[index].id_coa = coa.id
      this.data.journals[index].kode_coa = coa.kode
      this.data.journals[index].nama_coa = coa.nama
      this.modalCoa.active = false
    },

    confirmPosting () {
      this.$vs.dialog({
        type: 'confirm',
        color: 'success',
        title: 'Konfirmasi',
        text: 'Yakin ingin melanjutkan?',
        acceptText: 'Ya, Lanjutkan',
        cancelText: 'Batal',
        accept: () => {
          this.posting()
        }
      })
    },

    posting () {
      this.loading.posting = true

      const params = convertToFormData(this.buildParams())
      PengakuanPendapatanRepository.postingPendapatanIpl(params)
        .then(response => {
          this.onSuccess()
        })
        .catch(error => {
          console.log(error)
          if (error.response.status === 422) {
            this.errors = error.response.data.errors
          } else {
            this.notifyError('Terjadi kesalahan.')
          }
        })
        .finally(() => {
          this.loading.posting = false
        })
    },

    buildParams () {
      return {
        id_ipl: this.idIpl,
        keterangan: this.data.keterangan,
        journals: this.data.journals
      }
    },

    onSuccess () {
      this.notifySuccess('Proses posting sukses')
      this.emitIsSuccess(true)
      this.emitModalIsActive(false)
      this.resetData()
    },

    resetData () {
      const exceptObjects = _.pick(this.$data, [])
      const newData = Object.assign(this.$options.data.call(this), exceptObjects)
      Object.assign(this.$data, newData)
    },

    emitIsSuccess (isSuccess) {
      this.$emit('success', isSuccess)
    },

    emitModalIsActive (isActive) {
      this.$emit('update:isActive', isActive)
    }
  }
}
</script>
